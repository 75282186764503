<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card>
            <b-card-header>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input :label="$t('From Date')">
                      <flat-picker
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{
                          allowInput: true,
                          dateFormat: 'Y-m-d',
                          altFormat: 'Y-m-d',
                          ariaDateFormat: 'Y-m-d',
                          enableTime: false,
                          minDate: '2021-01-01',
                          maxDate: 'today',
                        }"
                        class="form-control datepicker"
                        v-model="cur_start_dt"
                      >
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('To Date')">
                      <flat-picker
                        slot-scope="{ focus, blur }"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{
                          allowInput: true,
                          dateFormat: 'Y-m-d',
                          altFormat: 'Y-m-d',
                          ariaDateFormat: 'Y-m-d',
                          enableTime: false,
                          minDate: '2021-01-01',
                          maxDate: 'today',
                        }"
                        class="form-control datepicker"
                        v-model="cur_to_dt"
                      >
                      </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select class="form-control" v-model="cur_store_id">
                        <option
                          v-for="st in storelist"
                          :key="st.store_id"
                          :value="st.store_id"
                        >
                          {{ st.name }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Name')">
                      <b-input
                        v-model="cur_name"
                        placeholder="Name"
                        class="form-control"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Telephone')">
                      <b-input
                        v-model="cur_phone"
                        placeholder="Phone"
                        class="form-control"
                      />
                    </base-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" class="mt-4" @click="get_data"
                      >Search</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </b-card-header>
            <p class="p-3" v-if="reservelist">
              <el-table
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="reservelist"
              >
                <el-table-column :label="$t('Number')">
                  <template v-slot="{ row }">
                    {{ 'R' + (row.id % 10000) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Reserve')" prop="tm">
                </el-table-column>
                <el-table-column :label="$t('Name')" prop="name">
                </el-table-column>
                <el-table-column :label="$t('Telephone')" prop="phone">
                </el-table-column>
                <el-table-column :label="$t('Status')" prop="status">
                </el-table-column>
                <el-table-column :label="$t('Remarks')" prop="notes">
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import InputSubmit from "../InputSubmit";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    InputSubmit,
    flatPicker,
  },
  data() {
    return {
      cur_start_dt: "",
      cur_to_dt: "",
      cur_store_id: "",
      cur_name: "",
      cur_phone: "",

      storelist: null,
      reservelist: null,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    async get_data() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      bodyFormData.append("store_id", this.cur_store_id);
      bodyFormData.append("name", this.cur_name);
      bodyFormData.append("phone", this.cur_phone);
      bodyFormData.append("start_dt", this.cur_start_dt);
      bodyFormData.append("to_dt", this.cur_to_dt);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Reserve/info",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              if (that.storelist.length <= 0) {
                that.$router.push("/store");
                return;
              }
              that.reservelist = rt.data.reserves;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/weblogin");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/weblogin");
        return;
      }
      return this.get_data();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.search-button {
  margin-top: 2rem;
}
.yesno {
  font-weight: bold;
  padding-right: 1.5rem;
}
</style>
